import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Card from "../../components/shared/card"
import Grid from "../../components/shared/grid"

export default function Jednodjelne() {
  return (
    <StaticQuery
      query={fetchProducts}
      render={data => (
        <Grid>
          {data.allSanityProduct.nodes.map(product => (
            <Link
              key={product.defaultProductVariant.sku}
              to={product.slug.current}
            >
              <Card
                text={product.title}
                src={product.defaultProductVariant.images.asset.fluid.srcWebp}
              />
            </Link>
          ))}
        </Grid>
      )}
    />
  )
}

export const fetchProducts = graphql`
  query {
    allSanityProduct(sort: { fields: _createdAt, order: DESC }, limit: 40) {
      nodes {
        title
        slug {
          current
        }
        defaultProductVariant {
          sku
          images {
            asset {
              fluid(maxWidth: 500) {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
